<template>
  <div class="layout-subcontainer">
    <!-- 프레임워크 구조 설명 -->
    <v-card class="flex-sm-grow-1 pa-3" elevation="2" max-width="" outlined>
      <v-card-title primary-title class="font-weight-bold primary--text">
        프레임워크 구조 설명
      </v-card-title>
      <v-row>
        <v-col>
          <!-- <v-treeview :items="items" open-all></v-treeview> -->
          <!-- open-all: tree 전체 열여 있게 하기 옵션 -->
          <template>
            <v-treeview
              :items="items"
              activatable
              item-key="name"
              open-on-click
            >
              <template v-slot:prepend="{ item, open }">
                <v-icon v-if="!item.file">
                  {{ open ? "mdi-folder-open" : "mdi-folder" }}
                </v-icon>
                <v-icon v-else>
                  {{ files[item.file] }}
                </v-icon>
              </template>

              <template v-slot:label="{ item }">
                <v-hover v-slot:default="{ hover }">
                  <div>
                    <span>{{ item.name }}</span>
                    <v-icon
                      v-if="hover"
                      class="block jh-5 subtitle-1"
                      color="primary"
                      >{{ item.remark }}</v-icon
                    >
                  </div>
                </v-hover>
              </template>
            </v-treeview>
          </template></v-col
        ></v-row
      >
      <v-row class="pt-5">
        <v-col>
          <template v-for="(item, index) in discriptionItem">
            <help-discription
              :key="`subitem-${index}`"
              :item="item"
              class="blue lighten-5"
            ></help-discription>
          </template>
        </v-col> </v-row
    ></v-card>
  </div>
</template>

<script>
import HelpDiscription from "./Discription";
export default {
  name: "HelpLayout",
  components: {
    HelpDiscription,
  },
  data: () => ({
    files: {
      html: "mdi-language-html5",
      js: "mdi-nodejs",
      json: "mdi-code-json",
      md: "mdi-language-markdown",
      pdf: "mdi-file-pdf",
      png: "mdi-file-image",
      txt: "mdi-file-document-outline",
      xls: "mdi-file-excel",
      vue: "mdi-vuejs",
      css: "mdi-language-css3",
    },
    items: [
      {
        id: 1,
        name: "Applications",
        remark: "ROOT",
        children: [
          { name: "dist", remark: "번들링된 파일(실재 사용 소스)" },
          { name: "node_modules", remark: "설치된 기본 패키지 저장 공간" },
          {
            name: "public",
            remark: "전처리 없는 고정 데이터(favicon 이미지 등)",
          },
          {
            name: "src",
            remark: "프로그램 개발 파일",
            children: [
              { name: "assets", remark: "css, image 등 정적 자산" },
              { name: "components", remark: "공통 컴포넌트" },
              {
                name: "layouts",
                remark: "레이아웃 셋팅",
                children: [
                  {
                    name: "auth",
                    remark: "Top, Left등이 없는 백지 레이아웃",
                  },
                  { name: "default", remark: "기본 레이아웃" },
                ],
              },
              { name: "mixin", remark: "공통 자원 관리(고정데이터)" },
              { name: "plugins", remark: "기타 설치 플러그인" },
              {
                name: "router",
                remark: "라우팅 셋팅",
                children: [
                  { name: "index.js", file: "js", remark: "라우팅 설정 파일" },
                ],
              },
              { name: "store", remark: "공통 자원 관리(유동데이터)" },
              {
                name: "styless",
                remark: "스타일 셋팅",
                children: [
                  {
                    name: "custom.sass",
                    file: "css",
                    remark: "사용자 설정 스타일",
                  },
                  {
                    name: "variables.scss",
                    file: "css",
                    remark: "사용자 재설정 vuetify 스타일",
                  },
                ],
              },
              {
                name: "views",
                remark: "레이아웃 셋팅",
                children: [
                  {
                    name: "auth",
                    remark: "로그인, 로그아웃등 권한 관련 페이지",
                  },
                  { name: "help", remark: "개발 가이드 페이지(개발자용 메뉴)" },
                  { name: "page", remark: "사용자 페이지" },
                ],
              },
            ],
          },
          { name: "App.vue", file: "vue", remark: "기본 파일" },
          { name: "main.js", file: "js", remark: "기본 파일" },
          { name: "package.json", file: "json", remark: "기본 파일" },
        ],
      },
    ],
    discriptionItem: [
      {
        title: "라우팅 추가",
        text: "route/index.js 파일에 라우팅을 추가 후 layouts의 메뉴에 추가하면 된다",
      },
      {
        title: "스타일 변경 및 추가",
        text: "1. 기본 스타일 재정의: styles/variables.scss 파일에 기본설정을 재정의 하면 프로그램 랜더링시 오버라이트 되어 적용된다.<br>2. 사용자 정의 스타일: styles/custom.sass 파일에 추가하면 스타일이 추가된다",
      },
      {
        title: "아이콘 사용",
        text: "Material Design 사이트를 참고하여 적용 <br/><a href='https://materialdesignicons.com/' target='_blank'>https://materialdesignicons.com</a>",
      },
    ],
  }),
};
</script>

<style scoped></style>
