var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"layout-subcontainer"},[_c('v-card',{staticClass:"flex-sm-grow-1 pa-3",attrs:{"elevation":"2","max-width":"","outlined":""}},[_c('v-card-title',{staticClass:"font-weight-bold primary--text",attrs:{"primary-title":""}},[_vm._v(" 프레임워크 구조 설명 ")]),_c('v-row',[_c('v-col',[[_c('v-treeview',{attrs:{"items":_vm.items,"activatable":"","item-key":"name","open-on-click":""},scopedSlots:_vm._u([{key:"prepend",fn:function(ref){
var item = ref.item;
var open = ref.open;
return [(!item.file)?_c('v-icon',[_vm._v(" "+_vm._s(open ? "mdi-folder-open" : "mdi-folder")+" ")]):_c('v-icon',[_vm._v(" "+_vm._s(_vm.files[item.file])+" ")])]}},{key:"label",fn:function(ref){
var item = ref.item;
return [_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('div',[_c('span',[_vm._v(_vm._s(item.name))]),(hover)?_c('v-icon',{staticClass:"block jh-5 subtitle-1",attrs:{"color":"primary"}},[_vm._v(_vm._s(item.remark))]):_vm._e()],1)]}}],null,true)})]}}])})]],2)],1),_c('v-row',{staticClass:"pt-5"},[_c('v-col',[_vm._l((_vm.discriptionItem),function(item,index){return [_c('help-discription',{key:("subitem-" + index),staticClass:"blue lighten-5",attrs:{"item":item}})]})],2)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }